// eslint-disable-next-line no-restricted-globals
import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/checkoutBtn.css";
import axios from "axios";
import Discount from "./Discount";
import PaymentMethod from "./PaymentMethod";
import DiscountOn from "./DiscountOn";
import moment from "moment-timezone";
interface CartItem {
  id: string;
  imageUrl: string;
  name: string;
  amount: number;
  price: number;
  note: string;
}

interface CartProps {
  cart: CartItem[];
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  handleChange: (item: CartItem, value: number, note: string) => void;
}

const Cart = ({ cart, setCart, handleChange }: CartProps) => {
  const [price, setPrice] = useState(0);
  const [orders, setOrder] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [orderId, setOrderId] = useState<any>(null);
  const [discount, setDiscount] = useState<any>(0);
  const [paymentMethod, setPaymentMethod] = useState<any>("TRANSFER");
  const [discountOn, setDiscountOn] = useState<any>("HANDMADE");
  const handleRemove = (id: string) => {
    const arr = cart.filter((item) => item.id !== id);
    setCart(arr);
    handlePrice();
    toast.error("Xoá khỏi đơn hàng", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handlePrice = () => {
    let ans = 0;
    cart.forEach((item) => (ans += item.amount * item.price));
    setPrice(ans);
  };

  const handlePDFFile = (data: any) => {
    const binaryString = atob(data);
    const len = binaryString.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/pdf" });
    const invoice = window.URL.createObjectURL(blob);
    return invoice;
  };

  const finishOrder = (id: string) => {
    if (window.confirm("Bạn có muốn hoàn thành đơn hàng này không?")) {
      axios
        .post(
          `/v1/orders/${id}/finish`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response: any) => {
          axios
            .get("/v1/orders?page=0&limit=10&status=DRAFT", {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            })
            .then((response: any) => {
              setOrder(response.data);
              setDiscount(0);
              setDiscountOn("HANDMADE");
              setPaymentMethod("TRANSFER");
              toast.success("Hoàn thành đơn hàng", {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
          const invoice = handlePDFFile(response.data);
          window.open(invoice);
        });
    }
  };

  useEffect(() => {
    handlePrice();
    if (cart.length === 0) {
      axios
        .get("/v1/orders?page=0&limit=10&status=DRAFT", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response: any) => {
          setOrder(response.data);
        });
    }
  }, [cart]);

  const handleOrder = () => {
    const products = cart.map((item) => {
      return {
        productId: item.id,
        quantity: item.amount,
        note: item.note,
      };
    });
    if (!orderId) {
      axios
        .post(
          "/v1/orders",
          {
            products: products,
            customer: {
              name: customerName,
              phone: phone,
            },
            discount,
            discountOn,
            voucherCode: `${discount}`,
            paymentMethod,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response: any) => {
          setCart([]);
          toast.success("Sửa đơn thành công", {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      axios
        .patch(
          `/v1/orders/${orderId}`,
          {
            products: products,
            customer: {
              name: customerName,
              phone: phone,
            },
            voucherCode: `${discount}`,
            discount,
            discountOn,
            paymentMethod,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response: any) => {
          setOrderId(null);
          setCart([]);
        });
    }
  };

  const editOrder = (orderId: string) => {
    setOrderId(orderId);
    axios
      .get(`/v1/orders/${orderId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setOrder([]);
        setCart(
          response.data.products.map((item: any) => {
            return {
              id: item.productId,
              imageUrl: item.productImage || "",
              name: item.productName,
              amount: item.quantity,
              note: item.note,
              price: item.price,
            };
          })
        );

        setPaymentMethod(response.data.paymentMethod);
        setDiscount(+response.data.voucherCode);
        setDiscountOn(response.data.discountOn);
      });
  };

  const showOrderAgain = () => {
    axios
      .get("/v1/orders?page=0&limit=10", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response: any) => {
        setOrder(response.data);
      });
  };

  const printOrder = (orderId: string) => {
    axios
      .get(`/v1/orders/${orderId}/print`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response: any) => {
        const invoice = handlePDFFile(response.data);
        window.open(invoice);
      });
  };

  return (
    <>
      <section className="w-full align-center items-center container flex justify-center">
        <section className="text-white w-full p-5">
          {cart.length === 0 && (
            <button
              type="button"
              className="text-white bg-yellow-700 hover:bg-yellow-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800  inline-flex items-center"
              onClick={() => showOrderAgain()}
            >
              <svg
                className="w-6 h-6 text-white-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"
                />
              </svg>
              <span> In lại order</span>
            </button>
          )}
          {cart.length === 0 && orders.length > 0 ? (
            <div id="accordion-collapse" data-accordion="collapse">
              {orders.map((order: any) => {
                return (
                  <div>
                    <h5 id="accordion-collapse-heading-1">
                      <button
                        type="button"
                        className="flex items-center justify-between w-full p-3 font-medium rtl:text-right border border-b-0 border-gray-200 focus:ring-gray-200 dark:text-gray-400 gap-3"
                        data-accordion-target="#accordion-collapse-body-1"
                        aria-expanded="true"
                        aria-controls="accordion-collapse-body-1"
                      >
                        <span>
                          {`Đơn: ${order.orderNumber
                            .toString()
                            .padStart(7, "0")} -  ${
                            order.customer.name || "GUEST"
                          }`}
                          <span>
                            -
                            {order.paymentMethod === "TRANSFER"
                              ? " Chuyển khoản"
                              : " Tiền mặt"}{" "}
                            -{" "}
                            {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
                          </span>
                        </span>
                        <span>
                          {order.status === "DRAFT" && (
                            <button
                              type="button"
                              className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              onClick={() => editOrder(order._id)}
                            >
                              Sửa
                            </button>
                          )}
                          {order.status === "DRAFT" && (
                            <button
                              type="button"
                              className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              onClick={() => finishOrder(order._id)}
                            >
                              Hoàn thành
                            </button>
                          )}
                          {order.status === "FINISHED" && (
                            <button
                              type="button"
                              className="text-white bg-yellow-700 hover:bg-yellow-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 inline-flex items-center"
                              onClick={() => printOrder(order._id)}
                            >
                              <svg
                                className="w-6 h-6 text-white-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M16.444 18H19a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h2.556M17 11V5a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6h10ZM7 15h10v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-4Z"
                                />
                              </svg>

                              <span>In</span>
                            </button>
                          )}
                        </span>
                      </button>
                    </h5>
                    <div
                      id="accordion-collapse-body-1"
                      aria-labelledby="accordion-collapse-heading-1"
                    >
                      <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                        <div className="relative overflow-x-auto">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  Tên sản phẩm
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Số lượng
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Thành tiền
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Note
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Trạng thái
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.products.map((product: any) => {
                                return (
                                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-6 py-4 font-bold">
                                      {product.productName}
                                    </td>
                                    <td className="px-6 py-4">
                                      {product.quantity}
                                    </td>
                                    <td className="px-6 py-4 font-bold">
                                      {new Intl.NumberFormat().format(
                                        product.quantity * product.price
                                      )}
                                    </td>
                                    <td className="px-6 py-4 font-bold text-blue-600">
                                      {product.note}
                                    </td>
                                    <td
                                      className="px-6 py-4"
                                      style={{
                                        color:
                                          order.status === "FINISHED"
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {order.status === "FINISHED"
                                        ? "Hoàn thành"
                                        : "Lưu nháp"}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4 font-bold">
                                  Tổng tiền
                                </td>
                                <td className="px-6 py-4"></td>
                                <td className="px-6 py-4 font-bold">
                                  {new Intl.NumberFormat().format(
                                    order.totalPrice
                                  )}
                                </td>
                                <td className="px-6 py-4 font-bold"></td>
                                <td
                                  className="px-6 py-4"
                                  style={{
                                    color:
                                      order.status === "FINISHED"
                                        ? "green"
                                        : "red",
                                  }}
                                ></td>
                              </tr>
                              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4 font-bold">
                                  Khuyến mại ({order.voucherCode}%)
                                </td>
                                <td className="px-6 py-4"></td>
                                <td className="px-6 py-4 font-bold">
                                  {new Intl.NumberFormat().format(
                                    order.discountValue
                                  )}
                                </td>
                                <td className="px-6 py-4 font-bold"></td>
                                <td
                                  className="px-6 py-4"
                                  style={{
                                    color:
                                      order.status === "FINISHED"
                                        ? "green"
                                        : "red",
                                  }}
                                ></td>
                              </tr>
                              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4 font-bold">
                                  Số tiền phải thu
                                </td>
                                <td className="px-6 py-4"></td>
                                <td className="px-6 py-4 font-bold text-red-600">
                                  {new Intl.NumberFormat().format(
                                    order.totalPrice - order.discountValue
                                  )}
                                </td>
                                <td className="px-6 py-4 font-bold"></td>
                                <td
                                  className="px-6 py-4"
                                  style={{
                                    color:
                                      order.status === "FINISHED"
                                        ? "green"
                                        : "red",
                                  }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="customerName"
                  className="block text-sm font-medium leading-6 text-white-900"
                >
                  Tên khách hàng
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="customerName"
                    id="customerName"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-200 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setCustomerName(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white-900"
                >
                  Số điện thoại
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                </div>
              </div>
              <Discount
                discount={discount}
                onChooseDiscount={(discount) => setDiscount(discount)}
              />
              <PaymentMethod
                paymentMethod={paymentMethod}
                onChoosePaymentMethod={(paymentMethod) =>
                  setPaymentMethod(paymentMethod)
                }
              />
              <DiscountOn
                discountOn={discountOn}
                onChooseDiscountOn={(discountOn) => setDiscountOn(discountOn)}
              />
              {cart.map((item) => (
                <div>
                  <div
                    className="flex items-center justify-between mt-2 pb-2"
                    key={item.id}
                  >
                    <div className="flex w-80">
                      <img
                        src={item.imageUrl}
                        alt=""
                        className="w-16 h-16"
                        style={{ borderRadius: 50 }}
                      />
                      <p className="font-bold ml-5 mt-4">{item.name}</p>
                    </div>
                    <div className="flex items-center justify-between pb-2 mt-2">
                      <button
                        className="px-2.5 py-1.5 text-lg font-bold mr-1.5"
                        onClick={() => handleChange(item, -1, "")}
                      >
                        -
                      </button>
                      <button>{item.amount}</button>
                      <button
                        className="px-2.5 py-1.5 text-lg font-bold mr-1.5"
                        onClick={() => handleChange(item, 1, "")}
                      >
                        +
                      </button>
                    </div>
                    <div>
                      <span className="text-brandColor py-1.5 px-2.5 rounded-lg mr-2.5">
                        {Intl.NumberFormat().format(item.price)}
                      </span>
                      <button
                        className="py-2 px-2.5 font-semibold bg-red-100 rounded-lg cursor-pointer text-brandColor hover:text-red-600"
                        onClick={() => handleRemove(item.id)}
                      >
                        <FaTrash title="Remove from cart" />
                      </button>
                    </div>
                  </div>
                  <div className="border-b-2">
                    <div className="sm:col-span-3 mb-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-white-900"
                      >
                        Note
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="note"
                          id="note"
                          autoComplete="off"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue={item.note}
                          onChange={(e) =>
                            handleChange(item, 0, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {cart.length > 0 && (
            <>
              <div className="flex justify-between mt-8">
                <span className="text-lg font-semibold">Tổng tiền :</span>
                <span className="text-lg font-semibold text-brandColor">
                  {Intl.NumberFormat().format(price)}
                </span>
              </div>
              <section className="flex justify-between mt-12">
                <button onClick={handleOrder} className="checkout-btn">
                  {orderId ? "Sửa đơn" : "Tạo đơn "}
                </button>
              </section>
            </>
          )}
        </section>
      </section>
      <ToastContainer />
    </>
  );
};

export default Cart;
