import React, { useState, useEffect } from "react";
import Cards from "./Cards";
import { list } from "../data";
import { FaSearch } from "react-icons/fa";
import Cart from "./Cart";
import { toast } from "react-toastify";
import axios from "axios";
import axiosConfig from "../axiosConfig";

interface CartItem {
  id: string;
  name: string;
}

function Details() {
  const [category, setCategory] = useState<any[]>([]);
  const [productList, setProductList] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [cart, setCart] = useState<any[]>([]);

  const handleClick = (item: any) => {
    if (cart.some((cartItem) => cartItem.id === item.id)) return;
    setCart([...cart, { ...item, amount: 1, note: "" }]);
    toast.success("Thêm thành công", {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleBtns = (categoryId: string) => {
    axiosConfig
      .get(`v1/products?categoryId=${categoryId}&page=0&limit=60`)
      .then((response: any) => {
        setProductList(response.data);
        setActiveTab(categoryId);
      });
  };

  const handleChange = (item: any, d: number, note: string) => {
    setCart((prevCart) =>
      prevCart.map((cartItem) => {
        if (cartItem.id === item.id) {
          const updatedAmount = cartItem.amount + d;
          return {
            ...cartItem,
            amount: updatedAmount > 0 ? updatedAmount : 1,
            note,
          };
        }

        return cartItem;
      })
    );
  };

  const handlePDFFile = (data: any) => {
    const binaryString = atob(data);
    const len = binaryString.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/pdf" });
    const invoice = window.URL.createObjectURL(blob);
    return invoice;
  };

  const printImage = () => {
    axios
      .post(`/image/printImage`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response: any) => {
        const invoice = handlePDFFile(response.data);
        window.open(invoice);
      });
  };

  useEffect(() => {
    axiosConfig.get("v1/products?page=0&limit=60").then((response: any) => {
      setProductList(response.data);
    });

    axiosConfig.get("v1/categories?page=0&limit=10").then((response: any) => {
      setCategory(response.data);
    });
  }, []);

  return (
    <>
      <section className="pt-4 w-full bg-bgColor">
        <section className="px-6 flex flex-row justify-between">
          {/* <div className="relative w-80 h-11 mt-4">
            <input
              type="text"
              onChange={(event) => setQuery(event.target.value)}
              className="w-full h-full py-4 px-10 text-base text-black rounded-lg outline-none"
              placeholder="Search food..."
            />
            <i>
              <FaSearch className="absolute left-4 top-4 text-lg w-4 h-4 text-center text-black focus:outline-none" />
            </i>
          </div> */}

          <div className="flex flex-wrap mt-4 lg:mb-4 mb-8">
            <button
              value="All"
              onClick={() => handleBtns("All")}
              className={`mr-2 text-brandColor border-brandColor border-2 py-1 px-6 md:w-24 h-10 rounded-lg text-lg ${
                activeTab === "All"
                  ? "bg-brandColor outline-none text-white"
                  : ""
              }`}
            >
              All
            </button>
            {category.map((item) => {
              return (
                <button
                  value="All"
                  onClick={() => handleBtns(item.id)}
                  className={`mr-2 text-brandColor border-brandColor border-2 py-1 px-6 h-10 rounded-lg text-lg ${
                    activeTab === item.id
                      ? "bg-brandColor outline-none text-white"
                      : ""
                  }`}
                >
                  {item.name}
                </button>
              );
            })}
            {/* <button
              value="All"
              onClick={() => printImage()}
              className={`mr-2 text-brandColor border-brandColor border-2 py-1 px-6 h-10 rounded-lg text-lg`}
                >Print
                </button> */}
          </div>
        </section>
        <section className="flex flex-row">
          <section
            className="basis-2/4"
            style={{ maxHeight: 700, overflowY: "scroll" }}
          >
            <section className="flex flex-row flex-wrap">
              {productList.map((item: any) => {
                return (
                  <Cards key={item.id} item={item} handleClick={handleClick} />
                );
              })}
            </section>
          </section>
          <section className="basis-2/4">
            <Cart cart={cart} setCart={setCart} handleChange={handleChange} />
          </section>
        </section>
      </section>
    </>
  );
}

export default Details;
