import "../styles/button.css";

interface Item {
  name: string;
  price: number;
  imageUrl: string;
}

const Cards = ({
  item,
  handleClick,
}: {
  item: Item;
  handleClick: (item: Item) => void;
}) => {
  const { name, price, imageUrl } = item;

  return (
    <>
      <section className="flex flex-row lg:w-1/3 text-white w-full bg-bgColor">
        <div className="p-1 md:w-1/3 w-1/2 lg:w-full mb-4">
          <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-auto">
            <img
              className="lg:h-60 md:h-24 h-60 w-full object-cover object-center"
              src={imageUrl}
              alt="item"
            />
            <div className="px-3 py-2">
              <h1 className="text-l font-bold mb-3" style={{ height: 48 }}>
                {name}
              </h1>
              <div className="flex flex-wrap justify-between">
                <p className="leading-relaxed mt-4 text-m">
                  Giá: {Intl.NumberFormat().format(price)}
                </p>
                <button
                  onClick={() => handleClick(item)}
                  className="animated-btn"
                >
                  Đặt món
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cards;
